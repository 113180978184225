<template>
  <prm-dialog
    v-model:visible="visible"
    :draggable="false"
    modal
    :header="i18n.global.t('component.userSettingsModal.title')"
    :style="{ width: '100%', 'max-width': '700px' }"
    @hide="emit('close')"
  >
    <div class="input-group">
      {{ i18n.global.t('component.userSettingsModal.language') }}
      <prm-dropdown
        v-model="language"
        :options="languageList"
        option-value="id"
        option-label="displayName"
      />
    </div>
    <div class="input-group">
      {{ i18n.global.t('component.userSettingsModal.timeFormat') }}
      <prm-dropdown
        v-model="timeFormat"
        :options="localisationStoreDateFormatList"
        option-value="id"
        option-label="displayName"
      />
    </div>
    <div class="input-group">
      {{ i18n.global.t(`${I18N_PREFIX}.pageScaling`) }}
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          gap: var(--gap);"
      >
        <span>
          {{ i18n.global.t('generalTerm.tiny') }}
        </span>
        <div
          :style="{
            'font-size': `${fontSize}px`,
            width: '100%',
            'text-align': 'center',
          }"
        >
          {{ `${i18n.global.t('generalTerm.size')} ${fontSize} px` }}
          <prm-slider
            v-model="sliderSize"
            style="margin-top: 0.5rem;"
            @update:model-value="defineFontSize($event as number)"
          />
        </div>
        <span>
          {{ i18n.global.t('generalTerm.large') }}
        </span>
      </div>
    </div>
    <template #footer>
      <prm-button
        type="button"
        outlined
        :disabled="isSubmitting"
        @click="emit('close')"
      >
        {{ i18n.global.t('common.cancel') }}
      </prm-button>
      <prm-button
        type="button"
        :disabled="isSubmitting"
        @click="onSubmit"
      >
        {{ i18n.global.t('common.submit') }}
      </prm-button>
    </template>
  </prm-dialog>
</template>

<script lang="ts" setup>
import PrmDialog from 'primevue/dialog';
import {
  ref,
  shallowRef,
} from 'vue';
import PrmButton from 'primevue/button';
import {
  i18n,
} from '@i18n';
import {
  useToast,
} from 'primevue/usetoast';
import PrmDropdown from 'primevue/dropdown';
import PrmSlider from 'primevue/slider';
import {
  ELanguage,
  ELocalisationStoreDateFormat,
  localisationStoreDateFormatList,
  languageList,
} from '@/types/localization-store.ts';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage.ts';
import {
  EFontSize,
} from '@/types/font-size.ts';

const visible = ref(true);

const toast = useToast();
const customUserStorage = useCustomUserStorageStore();

const I18N_PREFIX = 'components/user-settings-modal/user-settings-modal';

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'close'): void,
  (e: 'success'): void,
}>();

const language = ref<ELanguage>(customUserStorage.customUserSettings!.language);
const timeFormat = ref<ELocalisationStoreDateFormat>(customUserStorage.customUserSettings!.timeFormat);
const isSubmitting = ref(false);

function defineSliderSize(size: EFontSize) {
  switch (size) {
    case (EFontSize.TINY): {
      return 0;
    }
    case (EFontSize.SMALL): {
      return 37;
    }
    case (EFontSize.NORMAL): {
      return 62;
    }
    case (EFontSize.LARGE): {
      return 100;
    }
    default: throw new Error('not implemented');
  }
}
const sliderSize = shallowRef<number>(
  (customUserStorage.customUserSettings && customUserStorage.customUserSettings.fontSize)
    ? defineSliderSize(customUserStorage.customUserSettings.fontSize)
    : 62,
);
const fontSize = shallowRef<EFontSize>(customUserStorage.customUserSettings!.fontSize || EFontSize.NORMAL);

function defineFontSize(size: number) {
  if (size <= 25) {
    fontSize.value = EFontSize.TINY;
    return;
  }

  if (size <= 50) {
    fontSize.value = EFontSize.SMALL;
    return;
  }

  if (size <= 75) {
    fontSize.value = EFontSize.NORMAL;
    return;
  }

  fontSize.value = EFontSize.LARGE;
}

function onSubmit() {
  isSubmitting.value = true;
  customUserStorage.createOrUpdateCustomUserSettings({
    language: language.value,
    timeFormat: timeFormat.value,
    fontSize: fontSize.value,
  }).then(() => {
    document.documentElement.style.setProperty('font-size', `${fontSize.value}px`);
    emit('success');
    toast.add({
      summary: i18n.global.t('common.success'),
      severity: 'success',
      life: 3000,
    });
  }).finally(() => {
    isSubmitting.value = false;
  });
}
</script>
