import {
  acceptHMRUpdate,
  defineStore,
} from 'pinia';
import {
  AxiosResponse,
} from 'axios';
import {
  ELanguage,
  ELocalisationStoreDateFormat,
  getDefaultLanguage,
  getDefaultTimeFormat,
} from '@/types/localization-store';
import {
  EDateFormatAmerican, EDateFormatEuropian,
} from '@/utils/date-formats';
import {
  asyncRequestActionList,
} from './util/request';
import {
  TGetAppListResponse,
  getAppList,
} from '@/api/app';
import {
  TGetCurrentUserResponse,
  getCurrentUser,
} from '@/api/user.ts';
import {
  useCustomUserStorageStore,
} from './custom-user-storage';
import {
  components,
} from '@/types/openapi';

// Needs to move localization features here from  Vuex "localizationStore" module as customUserStorage
export const useAppStore = defineStore('app', {
  state: () => ({
    appList: undefined as TGetAppListResponse | undefined,
    appListPromise: undefined as Promise<AxiosResponse<TGetAppListResponse>> | undefined,
    // is prefetched in auth-middleware
    // user will be undefined in any element outside of router view, for example header & navbar
    user: undefined as TGetCurrentUserResponse | undefined,
    userPromise: undefined as Promise<AxiosResponse<TGetCurrentUserResponse>> | undefined,
    isNavigating: false,
  }),
  getters: {
    getUserHasPermission: (state) => (appId: components['schemas']['AppId'], permissionName: components['schemas']['Permission'] | string) => state.user?.appPermissions?.[appId][permissionName] || false,
    appListIsLoading: (state) => state.appListPromise !== undefined,
    locale(): ELanguage {
      const customUserStorage = useCustomUserStorageStore();
      return customUserStorage.customUserSettings?.language || getDefaultLanguage();
    },
    dateFormat(): ELocalisationStoreDateFormat {
      const customUserStorage = useCustomUserStorageStore();
      return customUserStorage.customUserSettings?.timeFormat || getDefaultTimeFormat();
    },
    dayjsDateFormat(): typeof EDateFormatAmerican | typeof EDateFormatEuropian {
      const customUserStorage = useCustomUserStorageStore();
      switch (customUserStorage.customUserSettings?.language) {
        case 'de': return EDateFormatEuropian;
        case 'en': return EDateFormatAmerican;
        default: return EDateFormatEuropian;
      }
    },
  },
  actions: {
    async loadCurrentUser(force: boolean) {
      return asyncRequestActionList({
        callback: () => getCurrentUser(),
        list: this.user,
        promise: this.userPromise,
        setList: (data) => {
          this.user = data;
        },
        setPromise: (promise) => {
          this.userPromise = promise;
        },
        force,
      });
    },
    async loadAppList() {
      return asyncRequestActionList({
        callback: () => getAppList(),
        list: this.appList,
        promise: this.appListPromise,
        setList: (data) => {
          this.appList = data;
        },
        setPromise: (promise) => {
          this.appListPromise = promise;
        },
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
